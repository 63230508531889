
// Category Template
class CatTemplate {
  name: string;

  dir: string;

  constructor(name: string, dir?: string) {
    this.name = name
    if (dir === undefined) {
      this.dir = `/${name}`
    } else {
      this.dir = dir
    }
  }
}
export default {
  data() {
    return {
      mainCategories: [
        new CatTemplate('switch', '/'),
        // new CatTemplate('keycap'),
        // new CatTemplate('stabilizer'),
        // new CatTemplate('oring'),
        // new CatTemplate('lube'),
        // new CatTemplate('spring'),
        // new CatTemplate('kit'),
        // new CatTemplate('pcb'),
        // new CatTemplate('case'),
        // new CatTemplate('plate'),
      ],
    }
  },
}


import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    entry: Object,
    models: Object,
    modelGroups: Object,
  },
  data() {
    return {}
  },
})

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "modelName" }
const _hoisted_2 = { class: "modelCharacteristic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelGroups, (group, i) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "widget",
      key: i
    }, [
      _createElementVNode("h3", null, _toDisplayString(group.name), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.models.models.filter((e) => e.group === group.id), (model, j) => {
        return (_openBlock(), _createElementBlock("div", { key: j }, [
          _createElementVNode("p", _hoisted_1, _toDisplayString(model.name), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(model.characteristic, (item, k) => {
            return (_openBlock(), _createElementBlock("p", {
              class: "modelCharacteristic",
              key: k
            }, _toDisplayString(item + '; '), 1))
          }), 128))
        ]))
      }), 128)),
      _createElementVNode("p", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.characteristic, (item, j) => {
          return (_openBlock(), _createElementBlock("span", { key: j }, _toDisplayString(item + '; '), 1))
        }), 128))
      ])
    ]))
  }), 128))
}
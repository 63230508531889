import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tableHeader = _resolveComponent("tableHeader")!
  const _component_itemTable = _resolveComponent("itemTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tableHeader, { options: _ctx.options }, null, 8, ["options"]),
    _createVNode(_component_itemTable, { options: _ctx.options }, null, 8, ["options"])
  ]))
}
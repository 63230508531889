
import { defineComponent } from 'vue'
import tableHeader from '@/components/table-header.vue'
import itemTable from '@/components/table.vue' // @ is an alias to /src

export default defineComponent({
  name: 'home',
  components: {
    itemTable,
    tableHeader,
  },
  data() {
    return {
      options: {
        type: [],
        mount: [],
        profile: [],
      },
    }
  },
})

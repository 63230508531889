
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    img: String,
    topM: Array,
    topC: Array,
    stemC: Array,
    stemM: Array,
    contactC: Array,
    contactM: Array,
    springC: Array,
    springM: Array,
    bottomM: Array,
    bottomC: Array,
  },
})
